/**
 * 地点情報(都道府県)
 */
export type Municipality = {
  region_code: Number;
  prefecture_code: Number;
  prefecture_name: string;
  cities: City[];
};

/**
 * 市区町村
 */
export type City = {
  city_code: Number;
  city_name: string;
  villages: Village[];
};

/**
 * 村域以下
 */
export type Village = {
  village_code: Number;
  village_name: string;
};

/**
 * 地点コード
 */
export type MunicipalityCode = {
  region_code: Number;
  prefecture_code: Number;
  city_code: Number;
  village_code: Number;
};

/**
 * 地点コード(都道府県まで)
 */
export type PrefectureCode = {
  region_code: Number;
  prefecture_code: Number;
};

/**
 * 地点コードリストの各要素の0以降のフィールドを削除する
 * @param list
 */
export const removeMunicipalityCodeZero = (list: MunicipalityCode[]) => {
  const result: object[] = [];
  list.forEach((item: MunicipalityCode) => {
    // region_codeが0
    if (item.region_code === 0) {
      result.push({ region_code: item.region_code });
    }
    // すべてを含まない
    else if (!list.find((v) => v.region_code === 0)) {
      // 未設定(prefecture_codeが0)
      if (item.prefecture_code === 0) {
        result.push({ region_code: item.region_code });
      }
      // 都道府県(city_codeが0)
      else if (item.city_code === 0) {
        result.push({
          region_code: item.region_code,
          prefecture_code: item.prefecture_code,
        });
      }
      // 上位の都道府県の選択がない
      else if (
        !list.some(
          (v) =>
            v.region_code === item.region_code &&
            v.prefecture_code === item.prefecture_code &&
            v.city_code === 0 &&
            v.village_code === 0
        )
      ) {
        // 市区町村(village_codeが0)
        if (item.village_code === 0) {
          result.push({
            region_code: item.region_code,
            prefecture_code: item.prefecture_code,
            city_code: item.city_code,
          });
        }
        // 上位の市区町村の選択がない
        // 村域以下(上位の都道府県の選択がない)
        else if (
          !list.some(
            (v) =>
              v.region_code === item.region_code &&
              v.prefecture_code === item.prefecture_code &&
              v.city_code === item.city_code &&
              v.village_code === 0
          )
        ) {
          result.push({
            region_code: item.region_code,
            prefecture_code: item.prefecture_code,
            city_code: item.city_code,
            village_code: item.village_code,
          });
        }
      }
    }
  });
  return result;
};

/**
 * 地点コード(都道府県)リストからすべてが選ばれた時に都道府県コードのフィールドを削除する
 * @param list
 */
export const removePrefectureCodeZero = (list: PrefectureCode[]) => {
  const result: object[] = [];
  list.forEach((item: PrefectureCode) => {
    // region_codeが0
    if (item.region_code === 0) {
      result.push({ region_code: item.region_code });
    }
    // すべてを含まない
    else if (!list.find((v) => v.region_code === 0)) {
      // 未設定(prefecture_codeが0)
      if (item.prefecture_code === 0) {
        result.push({ region_code: item.region_code });
      }
      // 都道府県
      else {
        result.push({
          region_code: item.region_code,
          prefecture_code: item.prefecture_code,
        });
      }
    }
  });
  return result;
};
