/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
// #region import
import React, { useEffect, useState } from 'react';
import GetMunicipality from '../../api/MunicipalityAPI';
import { PostPushSend, SendRequestDto } from '../../api/PushSendAPI';
import {
  Municipality,
  MunicipalityCode,
  PrefectureCode,
  removeMunicipalityCodeZero,
  removePrefectureCodeZero,
} from '../../models/municipality';
import {
  DEVICE_TYPE_ANDROID_CODE,
  DEVICE_TYPE_IOS_CODE,
  GENDER_MALE_CODE,
  GENDER_FEMALE_CODE,
  GENDER_OTHER_CODE,
  AGE_10S_CODE,
  AGE_20S_CODE,
  AGE_30S_CODE,
  AGE_40S_CODE,
  AGE_50S_CODE,
  AGE_60S_CODE,
  AGE_UNDEFINED_CODE,
  ERROR_MESSAGE_API,
  GENDER_UNDEFINED_CODE,
} from '../../util/Const';
import getBytes from '../../util/StringUtil';
import Menu from '../compornent/Menu';
import {
  Title,
  Message,
  Additional,
  DeviceType,
  CurrentLocation,
  LivingLocation,
  Gender,
  Age,
  IsDebug,
} from '../compornent/PushSend/FormItem';
// #endregion import

/**
 * 通知送信画面
 * @param history
 */
const NotificationSending = (props: any) => {
  const { history } = props;

  // #region useState
  const [municipality, setMunicipality] = useState<Municipality[]>([]);
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [device, setDevice] = useState<string[]>([
    DEVICE_TYPE_ANDROID_CODE,
    DEVICE_TYPE_IOS_CODE,
  ]);
  const [currentLocation, setCurrentLocation] = useState<MunicipalityCode[]>([
    { region_code: 0, prefecture_code: 0, city_code: 0, village_code: 0 },
  ]);
  const [livingLocation, setLivingLocation] = useState<PrefectureCode[]>([
    { region_code: 0, prefecture_code: 0 },
  ]);
  const [gender, setGender] = useState<Number[]>([
    GENDER_MALE_CODE,
    GENDER_FEMALE_CODE,
    GENDER_OTHER_CODE,
    GENDER_UNDEFINED_CODE,
  ]);
  const [age, setAge] = useState<Number[]>([
    AGE_10S_CODE,
    AGE_20S_CODE,
    AGE_30S_CODE,
    AGE_40S_CODE,
    AGE_50S_CODE,
    AGE_60S_CODE,
    AGE_UNDEFINED_CODE,
  ]);
  const [isDebug, setIsDebug] = useState<Number>(1);
  // #endregion useState

  useEffect(() => {
    GetMunicipality()
      .then((response) => {
        setMunicipality(response);
      })
      .catch(() => {
        alert(ERROR_MESSAGE_API);
      });
  }, []);

  const onClickSendBtn = async () => {
    try {
      // タイトル
      if (title.length <= 0) {
        alert('タイトルを入力してください。');
        return;
      }
      if (getBytes(title) > 256) {
        alert('タイトルの文字数が多すぎます。');
        return;
      }
      // メッセージ
      if (message.length <= 0) {
        alert('メッセージを入力してください。');
        return;
      }
      if (getBytes(message) > 1024) {
        alert('メッセージの文字数が多すぎます。');
        return;
      }
      // 付加情報
      if (key.length <= 0 && value.length > 0) {
        alert('付加情報(key)を入力してください。');
        return;
      }
      if (getBytes(key) > 256) {
        alert('付加情報(key)の文字数が多すぎます。');
        return;
      }
      if (key.length > 0 && value.length <= 0) {
        alert('付加情報(value)を入力してください。');
        return;
      }
      if (getBytes(value) > 256) {
        alert('付加情報(value)の文字数が多すぎます。');
        return;
      }
      if (currentLocation.length <= 0) {
        alert('現在地を選択してください。');
        return;
      }
      if (livingLocation.length <= 0) {
        alert('居住地を選択してください。');
        return;
      }
      if (gender.length <= 0) {
        alert('性別を選択してください。');
        return;
      }
      if (age.length <= 0) {
        alert('年代を選択してください。');
        return;
      }
      const request: SendRequestDto = {
        title,
        message,
        device,
        current_location: removeMunicipalityCodeZero(currentLocation),
        living_location: removePrefectureCodeZero(livingLocation),
        gender,
        age,
        additional: key && value ? [{ key, value }] : [],
        is_debug: isDebug,
      };
      const result = confirm('選択された対象へ通知を送信します。');
      if (result) {
        await PostPushSend(request);
        history.push('/PushLog');
      }
    } catch (error) {
      alert(ERROR_MESSAGE_API);
    }
  };

  // #region return
  return (
    <div className="main_contents">
      <Menu history={history} />
      <section id="right_contents" className="notification_sending">
        <div>
          <dl>
            <Title title={title} setTitle={setTitle} />
            <Message message={message} setMessage={setMessage} />
            <Additional
              addKey={key}
              addValue={value}
              setKey={setKey}
              setValue={setValue}
            />
          </dl>
          <dl className="box zokusei">
            <DeviceType device={device} setDevice={setDevice} />
            <CurrentLocation
              municipality={municipality}
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation}
            />
            <LivingLocation
              municipality={municipality}
              livingLocation={livingLocation}
              setLivingLocation={setLivingLocation}
            />
            <Gender setGender={setGender} />
            <Age setAge={setAge} />
            <IsDebug isDebug={isDebug} setIsDebug={setIsDebug} />
          </dl>
          <button type="button" className="btn" onClick={onClickSendBtn}>
            送信
          </button>
        </div>
      </section>
    </div>
  );
  // #endregion return
};
export default NotificationSending;
