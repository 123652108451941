/* eslint-disable no-alert */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import GetHistory from '../../../api/PushLogAPI';
import {
  tuutiHistoryInfo,
  Additional,
  LivingLocation,
  CurrentLocation,
} from '../../../models/tuutiHistoryInfo';
import {
  ERROR_MESSAGE_API,
  TITLE_TEXT,
  PUSH_LOG_INDEX_TEXT,
  SEND_STATUS_TEXT,
  SEND_DATETIME_TEXT,
} from '../../../util/Const';
import { GetTimestamp, SendStatus } from '../GenericMethod';

const NotificationHistoryIndex = (props: any) => {
  const {
    history,
    // selectedHistory,
    setSelectedHistory,
  } = props;

  const [historyIndex, setHistoryIndex] = useState<tuutiHistoryInfo[]>([]);

  useEffect(() => {
    GetHistory()
      .then((response) => {
        const list: tuutiHistoryInfo[] = [];
        for (const historyItem of response.history) {
          const AdditionalItem: Additional = {
            key:
              historyItem.additional.length !== 0
                ? historyItem.additional[0].key
                : '',
            value:
              historyItem.additional.length !== 0
                ? historyItem.additional[0].value
                : '',
          };
          const historys: tuutiHistoryInfo = {
            id: historyItem.id,
            timestamp: historyItem.timestamp,
            status: historyItem.status,
            succeedDevices: historyItem.succeed_devices,
            failedDevices: historyItem.failed_devices,
            title: historyItem.title,
            message: historyItem.message,
            device: [],
            currentLocation: [],
            livingLocation: [],
            gender: [],
            age: [],
            additional: AdditionalItem,
            isDebug: historyItem.is_debug,
          };
          if (historyItem.devices) {
            for (const deviceitem of historyItem.devices) {
              historys.device.push(deviceitem);
            }
          }
          if (historyItem.gender) {
            for (const genderItem of historyItem.gender) {
              historys.gender.push(genderItem);
            }
          }
          if (historyItem.age) {
            for (const ageItem of historyItem.age) {
              historys.age.push(ageItem);
            }
          }
          if (historyItem.living_location) {
            for (const livingItem of historyItem.living_location) {
              const living: LivingLocation = {
                regionCode: livingItem.region_code,
                prefectureCode: livingItem.prefecture_code,
              };
              historys.livingLocation.push(living);
            }
          }
          if (historyItem.current_location) {
            for (const currentItem of historyItem.current_location) {
              const current: CurrentLocation = {
                regionCode: currentItem.region_code,
                prefectureCode: currentItem.prefecture_code,
                cityCode: currentItem.city_code,
                villageCode: currentItem.village_code,
              };
              historys.currentLocation.push(current);
            }
          }
          list.push(historys);
        }
        setHistoryIndex(list);
      })
      .catch(() => {
        alert(ERROR_MESSAGE_API);
      });
  }, []);

  if (!history) {
    return null;
  }
  const indexitem = historyIndex.map((historyItem: tuutiHistoryInfo) => {
    return (
      <tr
        key={historyItem.id}
        className="index"
        onClick={() => setSelectedHistory(historyItem)}
      >
        <td className="title">{historyItem.title}</td>
        <td>{SendStatus(historyItem.status)}</td>
        {historyItem.timestamp ? (
          <td>{GetTimestamp(historyItem.timestamp)}</td>
        ) : (
          <td />
        )}
      </tr>
    );
  });

  return (
    <article id="history_index" className="rireki">
      <h1>{PUSH_LOG_INDEX_TEXT}</h1>
      <div className="tablearea">
        <table className="rirekiindex_table" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <th scope="col" className="sticky">
                {TITLE_TEXT}
              </th>
              <th scope="col" className="sticky">
                {SEND_STATUS_TEXT}
              </th>
              <th scope="col" className="sticky">
                {SEND_DATETIME_TEXT}
              </th>
            </tr>
            {indexitem}
          </tbody>
        </table>
      </div>
    </article>
  );
};
export default NotificationHistoryIndex;
