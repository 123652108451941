/* eslint-disable no-restricted-syntax */

import {
  GENDER_MALE_TEXT,
  GENDER_FEMALE_TEXT,
  GENDER_OTHER_TEXT,
  GENDER_UNDEFINED_TEXT,
  GENDER_MALE_CODE,
  GENDER_FEMALE_CODE,
  AGE_10S_TEXT,
  AGE_20S_TEXT,
  AGE_30S_TEXT,
  AGE_40S_TEXT,
  AGE_50S_TEXT,
  AGE_60S_TEXT,
  AGE_UNDEFINED_TEXT,
  AGE_10S_CODE,
  AGE_20S_CODE,
  AGE_30S_CODE,
  AGE_40S_CODE,
  AGE_50S_CODE,
  AGE_60S_CODE,
  SENDCOMPLETION,
  SENDPROCESSING,
  GENDER_OTHER_CODE,
  GENDER_UNDEFINED_CODE,
} from '../../util/Const';

// 送信状況を返すmethod
export const SendStatus = (status: number) => {
  if (status === undefined) {
    return null;
  }
  switch (status) {
    case 1:
      return SENDCOMPLETION;
    default:
      return SENDPROCESSING;
  }
};
// 送信日時を返すmethod
export const GetTimestamp = (timestamp: string | undefined) => {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);

  return `${date.getFullYear()}/${
    date.getMonth() + 1
  }/${date.getDate()} ${date.getHours()}:${`00${date.getMinutes()}`.slice(-2)}`;
};

// 性別の数字に応じて文字配列を返すメソッド
export const GetGender = (items: number[]) => {
  const list: string[] = [];
  items.forEach((item: number) => {
    switch (item) {
      case GENDER_MALE_CODE:
        list.push(GENDER_MALE_TEXT);
        break;
      case GENDER_FEMALE_CODE:
        list.push(GENDER_FEMALE_TEXT);
        break;
      case GENDER_OTHER_CODE:
        list.push(GENDER_OTHER_TEXT);
        break;
      case GENDER_UNDEFINED_CODE:
        list.push(GENDER_UNDEFINED_TEXT);
        break;
      default:
        break;
    }
  });
  return list.join('/');
};

// 年代の数字に応じて文字配列を返すメソッド
export const GetAge = (items: number[]) => {
  const list: string[] = [];
  items.forEach((item: number) => {
    switch (item) {
      case AGE_10S_CODE:
        list.push(AGE_10S_TEXT);
        break;
      case AGE_20S_CODE:
        list.push(AGE_20S_TEXT);
        break;
      case AGE_30S_CODE:
        list.push(AGE_30S_TEXT);
        break;
      case AGE_40S_CODE:
        list.push(AGE_40S_TEXT);
        break;
      case AGE_50S_CODE:
        list.push(AGE_50S_TEXT);
        break;
      case AGE_60S_CODE:
        list.push(AGE_60S_TEXT);
        break;
      default:
        list.push(AGE_UNDEFINED_TEXT);
        break;
    }
  });
  return list.join('/');
};
