/* eslint-disable no-plusplus */
/**
 * バイト数を計算(半角1、全角2)
 * @param str 文字列
 */
export const getBytes = (str: string) => {
  let length = 0;
  for (let i = 0; i < str.length; i++) {
    const c = str.charCodeAt(i);
    if (
      (c >= 0x0 && c < 0x81) ||
      c === 0xf8f0 ||
      (c >= 0xff61 && c < 0xffa0) ||
      (c >= 0xf8f1 && c < 0xf8f4)
    ) {
      length += 1;
    } else {
      length += 2;
    }
  }
  return length;
};
export default getBytes;
