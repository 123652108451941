/* eslint-disable react/jsx-no-constructed-context-values */
import './style/layout.css';
import './style/pushLog.css';
import './style/pushSend.css';
import React, { createContext, Dispatch, useReducer } from 'react';
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ActionType, Reducer, StateType } from './reducer';
import Login from './views/pages/Login';
import PushSend from './views/pages/PushSend';
import PushLog from './views/pages/PushLog';

type ContextType = {
  state: StateType;
  dispatch: Dispatch<ActionType>;
};

const defaultState = {
  token: undefined,
};
export const context = createContext({} as ContextType);

const PrivateRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    sessionStorage.getItem('token') ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: '/' }} />
    );
  return <Route {...rest} render={routeComponent} />;
};

const App: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, defaultState);

  return (
    <Router>
      <context.Provider value={{ state, dispatch }}>
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoute exact path="/PushSend" component={PushSend} />
          <PrivateRoute exact path="/PushLog" component={PushLog} />
        </Switch>
      </context.Provider>
    </Router>
  );
};

export default App;
